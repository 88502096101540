<template>
  <Signa1ExpandableList
    :completions-query="completions"
    :label="$t('pages.inscriptions.chooseInscription')"
    :name-map="nameMap"
    :preselected-signum1="selectedSignum1"
    :show-deprecated="showDeprecated"
    @select="signumSelected"
  >
    <template v-slot:signum1="{ element: { current, signum1 } }">
      <a :name="signum1" />
      <InscriptionsList
        v-if="current"
        :signum1="signum1"
        :only-primary="!showDeprecated"
      />
    </template>
    <template v-slot:list-extras>
      <b-form-checkbox v-model="showDeprecated" name="show-deprecated" switch>
        {{ $t("pages.inscriptions.deprecatedSwitch") }}
      </b-form-checkbox>
    </template>
  </Signa1ExpandableList>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "InscriptionsTree",
  components: {
    InscriptionsList: () => import("./InscriptionsList"),
    Signa1ExpandableList: () => import("@/components/Signa1ExpandableList")
  },
  props: {
    selectedSignum1: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      completions: gql`
        query searchBySignum($search: String!) {
          completions: findBySignumSearch(search: $search) {
            id
            signumInscriptions {
              canonical
              signum {
                signum1 {
                  signum1
                }
                signum2
              }
            }
          }
        }
      `,
      showDeprecated: false
    };
  },
  methods: {
    signumSelected({ id }) {
      this.$router.push({
        name: "inscription",
        params: { id }
      });
    },
    buildName(signumInscriptions) {
      if (signumInscriptions == undefined) return "";
      let name = "",
        extra = [];
      signumInscriptions.forEach(si => {
        si.canonical
          ? (name = si.signum.signum1.signum1 + " " + si.signum.signum2)
          : extra.push(si.signum.signum1.signum1 + " " + si.signum.signum2);
      });
      return extra.length > 0
        ? name +
            " (" +
            extra.sort((a, b) => a.localeCompare(b, "sv")).join(", ") +
            ")"
        : name;
    },
    nameMap(element) {
      return {
        name: this.buildName(element.signumInscriptions),
        ...element
      };
    }
  }
};
</script>
